import axios from "axios";
import React, { useEffect, useState } from "react";
import { jordanCities } from "../../assets/city";

const Applications = () => {
  const [filterCity, setFilterCity] = useState("");
  const [filterCategory, setFilterCategory] = useState("");
  const [search, setSearch] = useState("");
  const [applications, setApplications] = useState([]);
  const [categories, setCategories] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  // Pagination states
  const [limit] = useState(10); // Fixed limit per page
  const [page, setPage] = useState(1); // Current page
  const [loading, setLoading] = useState(false); // Loading state

  const fetchAllCategory = async () => {
    try {
      const category = await axios.get(
        `${process.env.REACT_APP_API}/categories`
      );
      if (category?.data?.success) {
        setCategories(category?.data?.data);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  // Fetch applications with pagination and filters
  const fetchAllApplications = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API}/employee`,
        {
          params: { limit, page, filterCity, filterCategory, search },
        }
      );

      if (response?.data?.success) {
        setApplications(response?.data?.data);
        setTotal(response?.data?.totalCount);
        setTotalPages(response?.data?.totalPages);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllApplications();
    fetchAllCategory();
  }, [filterCity, filterCategory, page, search]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    setPage(1); // Reset to page 1 on search
  };

  const renderPagination = () => {
    if (totalPages <= 1) return null; // No need to display pagination for a single page

    const pages = [];
    const visibleRange = 2; // Number of pages before and after the current page

    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 || // Always show the first page
        i === totalPages || // Always show the last page
        (i >= page - visibleRange && i <= page + visibleRange) // Show pages around the current page
      ) {
        pages.push(
          <button
            key={i}
            onClick={() => setPage(i)}
            className={`px-3 py-2 mx-1 rounded ${
              page === i
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-800"
            } hover:bg-blue-400 hover:text-white`}
          >
            {i}
          </button>
        );
      } else if (
        (i === page - visibleRange - 1 && page > visibleRange + 1) || // Ellipsis before the current range
        (i === page + visibleRange + 1 && page < totalPages - visibleRange) // Ellipsis after the current range
      ) {
        pages.push(
          <span key={i} className="px-3 py-2 mx-1">
            ...
          </span>
        );
      }
    }

    return <div className="flex justify-center mt-4">{pages}</div>;
  };

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg p-4">
      <div className="flex space-x-4 mb-4">
        <div className="m-5 p-2">
          <label
            htmlFor="city"
            className="block text-sm font-medium text-gray-700"
          >
            تصفية حسب المدينة
          </label>
          <select
            id="city"
            name="city"
            value={filterCity}
            onChange={(e) => setFilterCity(e.target.value)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          >
            <option value="">جميع المدن</option>
            {jordanCities?.map((city) => (
              <option value={city.name} key={city.name}>
                {city.name}
              </option>
            ))}
          </select>
        </div>
        <div className="m-5 p-2">
          <label
            htmlFor="category"
            className="block text-sm font-medium text-gray-700"
          >
            تصفية حسب الفئة
          </label>
          <select
            id="category"
            name="category"
            value={filterCategory}
            onChange={(e) => setFilterCategory(e.target.value)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          >
            <option value="">جميع الفئات</option>
            {categories?.map((category, i) => (
              <option key={i} value={category.name}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="mb-4 text-lg font-semibold">إجمالي السجلات: {total}</div>

      <table className="w-full text-sm text-right rtl:text-right text-gray-700">
        <thead className="text-xs text-gray-800 uppercase bg-white">
          <tr>
            <th scope="col" className="px-6 py-3">
              الاسم
            </th>
            <th scope="col" className="px-6 py-3">
              رقم الجوال
            </th>
            <th scope="col" className="px-6 py-3">
              اسم الفئة
            </th>
            <th scope="col" className="px-6 py-3">
              الفئة الفرعية
            </th>
            <th scope="col" className="px-6 py-3">
              المدينة
            </th>
            <th scope="col" className="px-6 py-3">
              مكان السكن
            </th>
            <th scope="col" className="px-6 py-3">
              <span className="sr-only">عرض السيرة الذاتية</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {applications.map((item, index) => (
            <tr key={index} className="bg-white border-b hover:bg-gray-50">
              <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                {item?.fullName}
              </td>
              <td className="px-6 py-4">
                <a
                  href={`https://web.whatsapp.com/send?phone=+962${item.phoneNumber}`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-600 hover:underline"
                >
                  {item?.phoneNumber}
                </a>
              </td>
              <td className="px-6 py-4">{item?.category}</td>
              <td className="px-6 py-4">{item?.subCategory}</td>
              <td className="px-6 py-4">{item?.city}</td>
              <td className="px-6 py-4">{item?.description}</td>
              <td className="px-6 py-4 text-right">
                <a
                  href={`${process.env.REACT_APP_API}/upload/${item.cv}`}
                  className="font-medium text-blue-600 hover:underline"
                  target="_blank"
                  rel="noreferrer"
                >
                  عرض السيرة الذاتية
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="flex justify-center mt-4 space-x-2">
        {renderPagination()}
      </div>
    </div>
  );
};

export default Applications;
