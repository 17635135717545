export const jordanCities = [
    { name: "عمان" },
    { name: "الزرقاء" },
    { name: "إربد" },
    { name: "العقبة" },
    { name: "المفرق" },
    { name: "الكرك" },
    { name: "جرش" },
    { name: "مادبا" },
    { name: "الطفيلة" },
    { name: "معان" },
    { name: "البلقاء" },
    { name: "الجرش" },
    { name: "السلط" },
    { name: "الرمثا" }
];
