import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";

const CategoryModal = ({ isOpen, onClose, item }) => {
  const [category, setCategory] = useState(item?.name ?? "");
  const [subcategories, setSubcategories] = useState(item?.subCategory ?? [""]);

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const handleSubcategoryChange = (index, e) => {
    const newSubcategories = [...subcategories];
    newSubcategories[index] = e.target.value;
    setSubcategories(newSubcategories);
  };

  const handleAddSubcategory = () => {
    setSubcategories([...subcategories, ""]);
  };

  const handleRemoveSubcategory = (index) => {
    const newSubcategories = subcategories.filter((_, i) => i !== index);
    setSubcategories(newSubcategories);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Handle form submission
    if (item?._id) {
      try {
        const response = await axios.patch(
          `${process.env.REACT_APP_API}/categories/${item?._id}`,
          {
            name: category,
            status: true,
            subCategory: subcategories,
          }
        );

        if (response?.data?.success) {
          toast.success("تم الاضافة بنجاح");
          onClose();
          window.location.reload();
        }
      } catch (error) {
        toast.error("حدث خطأ ما");
        onClose();
      }
    } else {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API}/categories`,
          {
            name: category,
            status: true,
            subCategory: subcategories,
          }
        );

        if (response?.data?.success) {
          toast.success("تم الاضافة بنجاح");
          onClose();
          window.location.reload();
        }
      } catch (error) {
        toast.error("حدث خطأ ما");
        onClose();
      }
    }
  };
  return (
    <div
      className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50 overflow-auto"
      tabIndex="-1"
      aria-hidden={!isOpen}
    >
      <div className="relative p-4 w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow-lg">
          <div className="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900">اضف فئة</h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={onClose}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="p-6">
            {/* Form */}
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label
                  htmlFor="category"
                  className="block text-sm font-medium text-gray-700"
                >
                  الفئة
                </label>
                <input
                  type="text"
                  id="category"
                  name="category"
                  value={category}
                  onChange={handleCategoryChange}
                  required
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  اقسام الفئات
                </label>
                {subcategories.map((subcat, index) => (
                  <div key={index} className="flex items-center space-x-2">
                    <input
                      type="text"
                      value={subcat}
                      onChange={(e) => handleSubcategoryChange(index, e)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    />
                    <button
                      type="button"
                      onClick={() => handleRemoveSubcategory(index)}
                      className="text-red-500 hover:text-red-700 p-4"
                    >
                      إزالة
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={handleAddSubcategory}
                  className="mt-2 text-blue-600 hover:text-blue-800"
                >
                  Add Subcategory
                </button>
              </div>

              <button
                type="submit"
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryModal;
