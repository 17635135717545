import React from "react";

const CardSkeletonLoader = () => {
  return (
    <div className="flex justify-center items-center bg-white space-x-4 animate-pulse">
      <div className="w-64 p-6 bg-gray-200 border border-gray-200 rounded-lg shadow-lg">
        <div className="h-6 bg-gray-300 rounded-md mb-4"></div>
        <div className="h-8 bg-gray-300 rounded-lg"></div>
      </div>
    </div>
  );
};

export default CardSkeletonLoader;
