import React from "react";

const CompanyDetailsModal = ({ data, onClose }) => {
  return (
    <div
      className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-black bg-opacity-50 overflow-auto"
      tabIndex="-1"
    >
      <div className="bg-white w-11/12 max-w-2xl p-6 rounded-lg shadow-lg">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold text-gray-800">تفاصيل الشركة</h2>
          <button
            onClick={onClose}
            className="text-gray-600 hover:text-gray-800"
          >
            ✕
          </button>
        </div>
        <div className="space-y-4">
          <div>
            <h3 className="text-lg font-semibold text-gray-700">اسم الشركة</h3>
            <p className="text-gray-600">{data.companyName}</p>
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-700">رقم الجوال</h3>
            <p className="text-gray-600">{data.companyMobileNumber}</p>
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-700">طبيعة العمل</h3>
            <p className="text-gray-600">{data.companyNature}</p>
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-700">العنوان</h3>
            <p className="text-gray-600">
              {`${data.address.governorate}, شارع ${data.address.street}, منطقة ${data.address.area}, رقم المبنى ${data.address.buildingNumber}, بالقرب من ${data.address.near}, الطابق ${data.address.floor}, مكتب رقم ${data.address.officeNumber}`}
            </p>
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-700">
              الوظيفة المطلوبة
            </h3>
            {data.jobPosition.map((position, index) => (
              <div key={index} className="text-gray-600 space-y-1">
                <p>
                  <strong>المنصب:</strong> {position.position}
                </p>
                <p>
                  <strong>العدد المطلوب:</strong> {position.requiredNumber}
                </p>
                <p>
                  <strong>ساعات العمل:</strong> {position.workingHours} ساعات
                  (من {position.fromTime} إلى {position.toTime})
                </p>
                <p>
                  <strong>مكان الإقامة:</strong> بالقرب من{" "}
                  {position.residenceNear}
                </p>
                <p>
                  <strong>الجنس المطلوب:</strong> {position.gender}
                </p>
              </div>
            ))}
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-700">
              الفئة العمرية
            </h3>
            <p className="text-gray-600">
              من {data.ageRange.from} إلى {data.ageRange.to}
            </p>
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-700">
              الجنسية المطلوبة
            </h3>
            <p className="text-gray-600">{data.nationality}</p>
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-700">
              المؤهل العلمي
            </h3>
            <p className="text-gray-600">
              {data.education.master
                ? "ماجستير"
                : data.education.bachelor
                ? "باكالوريس"
                : data.education.diploma
                ? "دبلوم"
                : data.education.highSchool
                ? "ثانوية عامة"
                : "لا يشترط"}
            </p>
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-700">
              العمل الميداني
            </h3>
            <p className="text-gray-600">{data.fieldWork}</p>
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-700">شروط أخرى</h3>
            <p className="text-gray-600">{data.preferredConditions}</p>
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-700">العمولة</h3>
            <p className="text-gray-600">
              {data.commission.exists
                ? `نعم، النسبة ${data.commission.percentage}%`
                : "لا يوجد"}
            </p>
          </div>
          <div className="flex justify-between text-gray-600">
            <p>
              <strong>حوافز:</strong> {data.incentives ? "يوجد" : "لا يوجد"}
            </p>
            <p>
              <strong>ساعات إضافية:</strong>{" "}
              {data.overtime ? "يوجد" : "لا يوجد"}
            </p>
            <p>
              <strong>مواصلات:</strong>{" "}
              {data.securedTransport ? "مؤمنة" : "غير مؤمنة"}
            </p>
            <p>
              <strong>تأمين صحي:</strong>{" "}
              {data.healthInsurance ? "يوجد" : "لا يوجد"}
            </p>
            <p>
              <strong>ضمان اجتماعي:</strong>{" "}
              {data.socialSecurity ? "يوجد" : "لا يوجد"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetailsModal;
