import React, { useEffect, useState } from "react";
import Cards from "../../components/Cards";
import axios from "axios";
import CardSkeletonLoader from "../../components/CardSkeletonLoader";

const Home = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchAllCategory = async () => {
    try {
      setLoading(true);
      const category = await axios.get(
        `${process.env.REACT_APP_API}/categories`
      );

      if (category?.data?.success) {
        setData(category?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  useEffect(() => {
    fetchAllCategory();
  }, []);

  return (
    <>
      {loading && (
        <div className="flex gap-2 m-1 flex-wrap justify-center">
          {Array.from({ length: 20 }).map((_, index) => (
            <CardSkeletonLoader key={index} />
          ))}{" "}
        </div>
      )}
      <div className="flex gap-2 m-1 flex-wrap justify-center">
        {data?.map((item) => (
          <div key={item._id}>
            <Cards item={item} />
          </div>
        ))}
      </div>
    </>
  );
};

export default Home;
