// src/components/Footer.js

import React from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-white text-gray-700 py-8 border-t border-gray-200">
      <div className="container mx-auto flex flex-col items-center space-y-4">
        <div className="flex space-x-6">
          <FaInstagram className="w-7 h-7 hover:text-pink-500" />
          {/* <FaTwitter className="w-7 h-7 hover:text-blue-400" /> */}
          <FaFacebook className="w-7 h-7 hover:text-blue-500" />
        </div>
        <div className="text-center text-gray-500 text-sm">
          © 2024 شركة جروان للتوظيف. جميع الحقوق محفوظة.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
