import React from "react";

const PrivacyPolicyCompany = () => {
  return (
    <div className="container mx-auto p-5">
      {" "}
      {/* Adjust padding-top to match the navbar height */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-2">
          الشروط المتعلقة بالشركات
        </h2>
        <ul className="list-disc pl-6 space-y-2">
          <li>
            يجب على الشركة أو المؤسسة تعبئة الفورم أو الطلب وسيتم التواصل معكم
            من خلالنا.
          </li>
          <li>
            تعمل الشركة لدينا بتحصيل مبلغ مالي مقطوع من الموظف على أول راتب
            يتقاضاه فقط بمبلغ مالي المتفق عليه مسبقًا.
          </li>
          <li>
            نحن غير مسؤولين عن الموظف بعد قبوله وتوظيفه من قبلكم بأي شكل من
            الأشكال.
          </li>
        </ul>
      </section>
    </div>
  );
};

export default PrivacyPolicyCompany;
