import React, { useState } from "react";
import EmployeeModal from "../EmployeeModal";

const Cards = ({ item }) => {
  
  const [openModal, setOpenModal] = useState(false);
  return (
    <div className="flex justify-center items-center bg-white space-x-4">
      <div className="w-64 p-6 bg-white border border-gray-200 rounded-lg shadow-lg">
        <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900">
          {item?.name}
        </h5>
        <button
          onClick={() => setOpenModal(true)}
          className="inline-flex items-center px-3 py-2 mt-4 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          قدم الان
        </button>
      </div>

      {openModal && (
        <EmployeeModal
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          item={item}
        />
      )}
    </div>
  );
};

export default Cards;
