import React, { useState } from "react";
import Category from "./Category";
import SubCategory from "./SubCategory";
import Applications from "./Applications";
import Companies from "./Companies";
import Login from "./Login";

const Dashboard = () => {
  const [activeSection, setActiveSection] = useState("category");
  const [isLoggedIn, setIsLoggedIn] = useState(
    sessionStorage.getItem("isLoggedIn")
  );

  const handleLogin = () => {
    sessionStorage.setItem("isLoggedIn", true);
    setIsLoggedIn(true);
  };

  const renderContent = () => {
    switch (activeSection) {
      case "category":
        return <Category />;
      case "subcategory":
        return <SubCategory />;
      case "applications":
        return <Applications />;
      case "companies":
        return <Companies />;
      default:
        return <div>اختر فئة من الشريط الجانبي</div>;
    }
  };

  if (!isLoggedIn) {
    return <Login onLogin={handleLogin} />;
  }

  return (
    <div className="flex min-h-screen bg-gray-100 text-gray-900">
      {/* الشريط الجانبي */}
      <aside className="w-64 bg-white shadow-md">
        <div className="p-6">
          <h1 className="text-2xl font-semibold">لوحة التحكم الإدارية</h1>
        </div>
        <nav className="mt-6">
          <ul>
            <li className="my-2">
              <button
                onClick={() => setActiveSection("category")}
                className="block p-3 text-gray-700 rounded hover:bg-gray-200"
              >
                الفئة
              </button>
            </li>
            <li className="my-2">
              <button
                onClick={() => setActiveSection("applications")}
                className="block p-3 text-gray-700 rounded hover:bg-gray-200"
              >
                الطلبات
              </button>
            </li>
            <li className="my-2">
              <button
                onClick={() => setActiveSection("companies")}
                className="block p-3 text-gray-700 rounded hover:bg-gray-200"
              >
                الشركات
              </button>
            </li>
          </ul>
        </nav>
      </aside>

      {/* المحتوى الرئيسي */}
      <main className="flex-1 p-6">
        <h2 className="text-xl font-semibold mb-4">
          نظرة عامة على لوحة التحكم
        </h2>
        {renderContent()}
      </main>
    </div>
  );
};

export default Dashboard;
