import Navbar from "./components/NavBar";
import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import { ToastContainer } from "react-toastify";
import Dashboard from "./Pages/admin/Dashboard";
import Footer from "./components/Footer";

function App() {
  return (
    <div>
      <ToastContainer />
      <Navbar />
      <div className="p-[10em]">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/admin" element={<Dashboard />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
