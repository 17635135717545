import React from "react";

const PrivacyPolicyEmployee = () => {
  return (
    <div className="container mx-auto p-5">
      {/* Adjust padding-top to match the navbar height */}
      <h1 className="text-1xl font-bold mb-4"> شروط التقديم للوظيفة:</h1>
      <section>
        <ul className="list-disc pl-6 space-y-2 ">
          <li className="text-10">
            ملء الطلب كاملاً: يجب عليك إكمال جميع المعلومات المطلوبة في طلب
            التوظيف.
          </li>
          <li>
            لا حاجة لصور أو أوراق رسمية: لن نطلب منك أي صور شخصية أو وثائق رسمية
            عند التقديم.
          </li>
          <li>
            رسوم مقابلة: المقابلة مجانية بشكل كامل
          </li>
          <li>
            رسوم توظيف: إذا تم قبولك في الوظيفة، فستقوم الشركة بتحصيل رسوم توظيف
            عند حصولك على أول راتب لك. قيمة هذه الرسوم تبدأ من 50 دينار وتختلف
            حسب الراتب والوظيفة.
          </li> 
        </ul>
      </section>
    </div>
  );
};

export default PrivacyPolicyEmployee;
