import axios from "axios";
import React, { useEffect, useState } from "react";
import CategoryModal from "../../components/CategoryModal";

const Category = () => {
  const [categories, setCategories] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [itemData, setItemData] = useState({});

  const fetchAllCategory = async () => {
    try {
      const category = await axios.get(
        `${process.env.REACT_APP_API}/categories`
      );

      if (category?.data?.success) {
        setCategories(category?.data?.data);
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };
  useEffect(() => {
    fetchAllCategory();
  }, []);
  return (
    <div className="p-6">
      <h2 className="text-xl font-semibold mb-4">إدارة الفئات</h2>

      {/* إضافة فئة جديدة */}
      <div className="mb-6">
        <button
          onClick={() => setOpenModal(true)}
          className="mt-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
        >
          إضافة فئة
        </button>
      </div>

      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-700">
          <thead className="text-xs text-gray-800 uppercase bg-white">
            <tr>
              <th scope="col" className="px-6 py-3">
                الفئة
              </th>
              <th scope="col" className="px-6 py-3">
                تعديل
              </th>
            </tr>
          </thead>
          <tbody>
            {categories?.map((item, i) => (
              <tr
                className="bg-white border-b border-gray-200 hover:bg-gray-50"
                key={i}
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  {item.name}
                </th>
                <td className="px-6 py-4 text-right">
                  <button
                    onClick={() => {
                      setOpenUpdateModal(true);
                      setItemData(item);
                    }}
                    className="font-medium text-blue-600 hover:underline"
                  >
                    تعديل
                  </button>
                </td>
                {openUpdateModal && item?._id === itemData?._id && (
                  <CategoryModal
                    isOpen={openUpdateModal}
                    onClose={() => setOpenUpdateModal(false)}
                    item={item}
                    key={i}
                  />
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {openModal && (
        <CategoryModal
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          // item={item}
        />
      )}
    </div>
  );
};

export default Category;
